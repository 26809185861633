<template>
	<v-card max-width="500" class="mx-auto mt-5" elevation="0" :color="color" :dark="dark" rounded="xl">
		<div class="d-flex justify-center mb-2" v-if="$route.name == 'AuthResetPassword'">
			<v-btn plain fab active-class="" :to="{ name: 'Landing' }">
				<v-avatar size="96">
					<v-img src="@/assets/images/icon/logo-oversos-second-2x.png" />
				</v-avatar>
			</v-btn>
		</div>
		<v-card-title>
			<h1 class="display-1 mx-auto">
				{{ $t('auth.forgotPassword') }}
			</h1>
		</v-card-title>
		<v-card-text class="pb-0">
			<v-form ref="form" v-model="valid">
				<v-text-field
					v-model="email"
					:label="$t('auth.email')"
					:hint="$t('auth.emailHelp')"
					:rules="rules.email"
					persistent-hint
					required
					outlined
					rounded
					prepend-inner-icon="mdi-email"
					hide-details="auto"
					class="pb-3"
				/>
			</v-form>
			<p class="text-body-1 text-justify">
				{{ $t('auth.forgotPasswordButton') }}
			</p>
		</v-card-text>

		<v-alert v-show="action.message" :type="action.success ? 'success' : 'error'">
			{{ action.message }}
		</v-alert>

		<v-card-actions class="d-flex align-center justify-center flex-wrap pt-0">
			<v-btn class="px-4" color="primary" large rounded :loading="loading" @click="callSendResetPasswordEmail()">
				{{ $t('contact.send') }}
			</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
import { mapActions } from 'vuex'

export default {
	name: 'RequestPasswordReset',
	props: {
		color: {
			type: String,
			default: ''
		},
		dark: {
			type: Boolean,
			required: true
		}
	},
	data() {
		return {
			loading: false,
			valid: false,
			email: '',
			rules: {
				email: [
					(v) => !!v || this.$t('rules.required'),
					(v) => (v && v.indexOf('@') !== 0) || this.$t('rules.emailUsername'),
					(v) => (v && v.includes('@')) || this.$t('rules.emailAtSymbol'),
					(v) => (v && v.includes('.')) || this.$t('rules.emailPeriodSymbol'),
					(v) => (v && v.indexOf('.') <= v.length - 3) || this.$t('rules.emailDomain'),
					(v) => (v && /.+@.+\..+/.test(v)) || this.$t('rules.emailValid'),
					(v) => (v && v.length <= this.emailMaxLength) || this.$t('rules.length', { length: this.emailMaxLength })
				]
			},
			action: {
				success: false,
				message: ''
			}
		}
	},
	computed: {
		emailMaxLength() {
			return 255
		}
	},
	methods: {
		callSendResetPasswordEmail() {
			this.$refs.form.validate()
			if (!this.valid) return
			this.loading = true
			this.sendResetPasswordEmail(this.email)
				.then(({ success, response }) => {
					this.action = { success, message: response }
				})
				.then(() => {
					this.loading = false
				})
		},
		...mapActions('auth', ['sendResetPasswordEmail'])
	}
}
</script>
